.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.loading-spinner {
  border: 3px solid rgba(101, 92, 92, 0.2);
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.transition-background-color {
  transition: background-color 50ms ease-in-out;
}

/* width */
::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 100%;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.4);
}

.spinner {
  margin: auto;
  width: 50px;
  height: 30px;
}

.spinner > div {
  border-radius: 20px;
  background-color: #fff;
  height: 200%;
  width: 6px;
  float: left;
  margin: 0 1px;
  animation: stretch 2s infinite;
  animation-timing-function: cubic-bezier(0.62, 0.28, 0.23, 0.99);
}

.spinner .r1 {
  animation-delay: -1s;
}

.spinner .r2 {
  animation-delay: -0.9s;
}

.spinner .r3 {
  animation-delay: -0.8s;
}

.spinner .r4 {
  animation-delay: -0.7s;
}

.spinner .r5 {
  animation-delay: -0.6s;
}

@keyframes stretch {
  0%,
  20%,
  49% {
    transform: scaleY(0.4);
    opacity: 1;
  }

  10% {
    transform: scaleY(1);
  }

  50%,
  70%,
  100% {
    transform: scaleY(0.4);
    opacity: 0.2;
  }

  60% {
    transform: scaleY(1);
    opacity: 0.2;
  }
}
